<script setup lang="ts">
// vuetify
import { useTheme } from 'vuetify'

/**
 * vuetify
 * ==================================================================
 */
const vuetifyTheme = useTheme()
</script>

<template>
  <v-app>
    <v-main
      :style="{ background: vuetifyTheme.current.value.colors.backgroundgrey }"
    >
      <v-container class="mx-auto pt-0">
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>
